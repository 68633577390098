import Head from "next/head";
import weekDayNumber from "@data/calendarStartWeekDay/weekDayNumber.json";
import { Container, Row, Col } from "react-bootstrap";
import { Footer } from "@components/Layout/Footer";
import { useMain } from "@contexts/mainContext";
import {
  setCookies,
  getCookie,
  removeCookies,
  checkCookies,
} from "cookies-next";
//import useIsMountedRef from "@hooks/useIsMountedRef";
import { ColDiv, Main, RowFooter, RowDiv } from "./styles";
import { useCallback, useEffect, useState } from "react";
import {
  // getCurrencyInfo,
  getFaviconFromConfigBK,
  getUserCalType,
  getUserDateFormat,
  getUserProfileAPI,
} from "@lib/api/layout";

import { useRouter } from "next/router";
import { useUserProfile } from "@contexts/UserContext";
import { GoogleManager } from "./GoogleManager";
import { getCheckLogin } from "@lib/api/auth/getCheckLogin";
import { useAuth } from "@contexts/AuthContext";
import {
  //CERTIFICATE_CARD_TYPE_ID,
  FLIGHTS_LOGIN_REQUIRED,
  IMAGE_PATH,
  META_AUTHOR,
  META_DESCRIPTION,
  META_IMAGE,
  META_KEYWORDS,
  ORG_ID,
  SHOW_KEYS_LANG_LABELS,
} from "@helpers/config";
import { options } from "@services/swr.service";
import { SWRConfig } from "swr";
import { Drift } from "@components/Drift";
import {
  useCheckIp,
  useCheckLogin,
  useCheckUserLocation,
  useConfig,
  useCssPreAndPostLogin,
  useCurrencyLangInfo,
  useCurrencyRate,
  useFooter,
  useOrgBySite,
  useWhiteLabelLan,
} from "@hooks/useLayout";
import dynamic from "next/dynamic";
import { SignIn } from "@lib/api/auth/signIn";
import AuthService from "@services/auth.service";
import * as authHelper from "@helpers/AuthHelpers";
import { useCreadAdsRequest } from "@hooks/useAds";
import { useLayoutStore } from "@store/layoutStore";
import {
  useGetPaymentCardByUser,
  //useGetPaymentCardByOrg,
} from "@hooks/useWallet";
import { shallow } from "zustand/shallow";
import { useMembershipUpgradeStore } from "@store/membershipUpgradeStore";
import { getTodayDate, home } from "@helpers/helperAds";
import { ModalUserDemoMode } from "@components/Book/ModalUserDemoMode";

const HeaderV2 = dynamic(() => import("./HeaderV2"), {
  ssr: false,
});
const Loader = dynamic(() => import("@components/Loader"), {
  ssr: false,
});

const CrispWithNoSSR = dynamic(() => import("@components/CrispChat"), {
  ssr: false,
});

const Cookies = dynamic(() => import("@components/Cookies"), {
  ssr: false,
});
const HelpVideo = dynamic(() => import("@components/HelpVideos"), {
  ssr: false,
});

const HelpInstructions = dynamic(() => import("@components/HelpInstructions"), {
  ssr: false,
});

type LayoutProps = {
  title?: string;
  stickyTop?: boolean;
  showFooter?: boolean;
  swrOptions?: any;
  // configSite: ConfigSiteType;
};

export const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  stickyTop = false,
  showFooter = true,
  swrOptions = {},
  // configSite,
}) => {
  // const [googleTagId, setGoogleTagId] = useState<string | null>(null);
  const { isLogged, currentUser, saveUser, logout, saveAuth } = useAuth();
  const { setAdsCheckinDate, setCan_user_book, setShowUserDemoModal } =
    useLayoutStore();
  const {
    driftChat,
    faviconUrl,
    siteTitle,
    crispChatId,
    favIconExt,
    can_user_book,
    showUserDemoModal,
    canBookingWithoutSession,
  } = useLayoutStore(
    (state) => ({
      driftChat: state.externalConfig?.driftChat,
      faviconUrl: state.externalConfig?.faviconUrl,
      siteTitle: state.externalConfig?.siteTitle,
      crispChatId: state.externalConfig?.crispChatId,
      favIconExt: state.externalConfig?.faviconUrl,
      can_user_book: state.can_user_book,
      showUserDemoModal: state.showUserDemoModal,
      canBookingWithoutSession: state.externalConfig?.canBookingWithoutSession,
    }),
    shallow
  );
  const {
    ip,
    userType,
    showLoader,
    orgConfig,
    setCalendarStartDay,
    setDateFormat,
  } = useMain();
  const router = useRouter();
  const { setPlanStatus } = useMembershipUpgradeStore();
  const { setUserProfile, userProfile } = useUserProfile();
  const config = useConfig();
  const { currencies, languages } = useCurrencyLangInfo(
    config?.langCurrencyInfo
  );
  const [loadedUserProfile, setLoadedUserProfile] = useState(false);
  //const type: string = `${CERTIFICATE_CARD_TYPE_ID}`;
  //const user_id: number = Number(currentUser?.userId || 0);
  //const usr_type: number = 2;
  useWhiteLabelLan(config?.whiteLabelLang, SHOW_KEYS_LANG_LABELS);
  useFooter(config?.footer);
  useCurrencyRate();
  useCheckLogin();
  useCreadAdsRequest();
  useCheckIp();
  useCheckUserLocation();
  useCssPreAndPostLogin();
  // useNationality();
  useGetPaymentCardByUser();
  /*   useGetPaymentCardByOrg({
    user_id,
    type,
    usr_type,
  }); */
  const faviconData = getFaviconFromConfigBK(config);

  const { googleTagId } = useOrgBySite(config?.orgBySite);
  const [hideBox, setHideBox] = useState(false);
  const UserProfile = useCallback(async () => {
    try {
      if (checkCookies("userData") && !loadedUserProfile) {
        console.log(
          "🚀 ~ file: index.tsx:143 ~ UserProfile ~ userProfile:",
          loadedUserProfile ? "true" : "false"
        );
        const response = await getUserProfileAPI(userType);

        const basicProfile = response?.basicprofile;
        const bprofile = basicProfile?.find((f) => f);
        if (bprofile && bprofile?.subscription_details?.length > 0) {
          const findActivePlan = bprofile?.subscription_details?.some(
            (f) => f?.plan_status === "ACTIVE"
          );
          setPlanStatus(findActivePlan);
        }

        setLoadedUserProfile(true);
        if (response) {
          setUserProfile(response);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserCalTypeApi = useCallback(async () => {
    try {
      if (ip) {
        const startDay: any = authHelper.getCalendarStartDayLocalStorage();
        if (startDay == -1) {
          const res = await getUserCalType(userType, ip);
          if (res) {
            let dayNameShort = res ? res.split("-")[0] : "Sun";
            const findDay = weekDayNumber?.weekDay?.find(
              (obj) => obj?.day == dayNameShort
            );
            if (findDay) {
              const day: any = findDay?.weekDayNumber || 0;
              setCalendarStartDay(day);
              authHelper.setCalendarStartDayLocalStorage(day);
            }
          }
        } else {
          setCalendarStartDay(startDay);
        }
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ip, userType]);

  const getUserDateFormatApi = useCallback(async () => {
    const json = {
      usr_type: 2,
      user_id: currentUser?.userId || 0,
    };
    try {
      const dayFormat = authHelper.getUserDateFormatStorage();
      if (!dayFormat) {
        const res = await getUserDateFormat(json);
        if (res) {
          setDateFormat(String(res || "DD/MM/YYYY"));
          authHelper.setUserDateFormatStorage(String(res || "DD/MM/YYYY"));
        }
      } else {
        setDateFormat(dayFormat);
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ! refactorizar esto por que debe estar en su propio custom hook
  const impersonate = useCallback(async () => {
    const query = router.query;
    if (query.key) {
      logout();
      localStorage.clear();
      localStorage.setItem("currency_code", "USD");
      localStorage.setItem("lan_id", "1");
      localStorage.setItem("lang_code", "en-US");
      localStorage.setItem("currency_id", "9");
      localStorage.setItem("site_referer", document.referrer);
      const jsonSignIn = {
        usr_type: process.env.NEXT_PUBLIC_USER_TYPE
          ? process.env.NEXT_PUBLIC_USER_TYPE
          : "2",
        email_id: "",
        password: "",
        impersonate_key: String(query.key),
        googletoken: "",
        fbtoken: "",
        site_url: "",
        accesscode: "",
        promotions: {
          isPromotionVisited: false,
        },
        userdetails: {
          usr_email_id: "",
          first_name: "",
          last_name: "",
          mastercode: "4521423",
        },
      };
      let ip = String(getCookie("ip"));
      console.log("query.key", ip, query.key, jsonSignIn);
      const responseSignin = await SignIn(jsonSignIn, ip);
      console.log("responseSignin", responseSignin);

      if (responseSignin.status == "success") {
        console.log("responseSignin if", responseSignin);
        const tripId = !["", 0, null, "null", undefined].includes(
          responseSignin.result?.trip_id
        )
          ? responseSignin.result?.trip_id
          : null;

        if (tripId) {
          sessionStorage.setItem("header_id", "1");
        }
        console.log("sso tripID no entro=  ", tripId);
        setCookies("userToken", responseSignin?.result?.token || "");

        // store Auth Response
        const userData = AuthService.setLogInInfo(
          responseSignin?.result,
          "Mail",
          null,
          currencies,
          languages
        );

        saveUser(userData);
        const checklogin = await getCheckLogin();

        if (checklogin?.status == "success") {
          //saveUser Json
          let checkloginResp = checklogin?.result;
          console.log("checkloginResp1", checkloginResp);
          // let jsonClient = { ...userData, role: checkloginResp?.role ?? "" };
          const tmpUser = {
            userName: userData.userName,
            userToken: userData.userToken,
            personalPicture: userData.personalPicture,
            email: userData.email,
            currency_code: userData.currency_code,
            language: userData.currency_code,
            nationality: userData.nationality,
            userId: userData.userId,
            role: checkloginResp?.role ?? "",
            first_name: responseSignin.result?.first_name ?? userData.userName,
            last_name: userData.last_name,
            auth: {
              api_token: userData.auth.api_token,
            },
          };
          console.log("jsonClientSigin- layoutImpersonate", tmpUser);
          saveAuth({
            api_token: userData.auth.api_token,
          });
          saveUser(tmpUser);
          setCookies(
            "ssoCurrency",
            String(responseSignin?.result?.currency_code)
          );
          setCookies("ssoLanguage", String(responseSignin?.result?.language));
          window.location.href = "/";
          // router.push({ pathname: "/" });
        } else {
          console.log("sso no entre al auth y redirecciona");
          const urlRedirect = !["", 0, null, "null", undefined].includes(
            checklogin?.message?.session_out_url
          )
            ? checklogin?.message?.session_out_url
            : checklogin?.message?.ssocheck?.logout_url;

          if (responseSignin?.result?.nationality) {
            localStorage.setItem(
              authHelper.NATIONALITY_KEY,
              responseSignin?.result?.nationality
            );
          }
          router.push({ pathname: urlRedirect });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    impersonate();
  }, [impersonate]);

  useEffect(() => {
    getUserDateFormatApi();
  }, [getUserDateFormatApi]);

  useEffect(() => {
    if (!router.isReady) return;
    // if (["/", "/flight", "/cars"].includes(router.pathname)) {
    if (["/"].includes(router.pathname)) {
      removeCookies("searchLatitude");
      removeCookies("searchLongitude");
    }

    if (home.includes(router.pathname)) {
      removeCookies("adsCheckinDate");
      setAdsCheckinDate(getTodayDate());
    }

    if (["/transfers", "/transfers/search-details"].includes(router.pathname)) {
      localStorage.removeItem("pickupIsAirport");
      localStorage.removeItem("dropoffIsAirport");
      localStorage.removeItem("transferCountryCodeFrom");
      localStorage.removeItem("transferCountryCodeTo");
      localStorage.removeItem("transferCardSelected");
      localStorage.removeItem("transBookData");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    if (FLIGHTS_LOGIN_REQUIRED) {
      if (
        [
          "/flight/search-details",
          "/flight/checkout",
          "/flight/checkout-success",
        ].includes(router.pathname)
      ) {
        if (!isLogged) {
          router.push({ pathname: "/flight" });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    UserProfile();
  }, [UserProfile]);

  useEffect(() => {
    getUserCalTypeApi();
  }, [getUserCalTypeApi]);

  useEffect(() => {
    const url = router.pathname;
    const stringsToCheck = ["/cars/search-details"];

    const containsString = stringsToCheck.some((str) => url.includes(str));
    setHideBox(containsString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (router.pathname != "/user-details") {
      try {
        if (checkCookies("user-detail-tab")) {
          removeCookies("user-detail-tab");
        }
        for (var i = 0; i < 10000; i++) {
          window.clearInterval(i);
        }
      } catch (e: any) {
        console.log(`error to delete all intervals for MyTrips`, { e });
      }
    }
  }, [router.pathname]);
  useEffect(() => {
    if (
      userProfile &&
      Array.isArray(userProfile?.basicprofile) &&
      userProfile?.basicprofile?.length > 0
    ) {
      const can_user_book_num: number | undefined =
        userProfile?.basicprofile?.find((f) => f)?.can_user_book;
      if (can_user_book != undefined) {
        setCan_user_book(can_user_book_num == 1);
      }
    } else {
      if (!!canBookingWithoutSession) {
        setCan_user_book(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, canBookingWithoutSession]);

  const getTitle = () => {
    const titleSSR = siteTitle || faviconData?.text;
    if (titleSSR) {
      return title ? `${title} | ${titleSSR}` : titleSSR;
    }
    return "";
  };

  return (
    <SWRConfig value={{ ...options, ...swrOptions }}>
      <Head>
        <title>{getTitle()}</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <meta name="description" content={META_DESCRIPTION} />
        <meta name="keywords" content={META_KEYWORDS} />
        <meta name="author" content={META_AUTHOR} />
        <meta itemProp="image" content={`${IMAGE_PATH}${META_IMAGE}`} />
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <noscript id="mui-insertion-point" />
        {/* Google fonts get automatically automized by Next.js: https://nextjs.org/blog/next-10-2#automatic-webfont-optimization */}
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={favIconExt || faviconUrl || faviconData?.icon}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={favIconExt || faviconUrl || faviconData?.icon}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
      </Head>
      {driftChat && <Drift />}
      {crispChatId ? (
        <CrispWithNoSSR crispChatId={crispChatId} currentUser={currentUser} />
      ) : null}

      <Loader show={showLoader} />
      <HeaderV2 isSticky={stickyTop} />
      {/* <Header sticky={stickyTop ? "top" : undefined} /> */}
      <Container
        fluid
        /* className={router.pathname == "/wallet-checkout" ? "" : ""} */
      >
        <GoogleManager googleTagId={googleTagId} />
        <RowDiv className="RowDivMapView">
          <ColDiv>
            <Main id={"main-container-app"} className="main-container-divs">
              {/* {JSON.stringify(configSite?.langCurrencyInfo)} */}
              {children}
            </Main>
          </ColDiv>
        </RowDiv>
        <RowFooter
          className={
            hideBox ? "footer footer_text hideWhenMObile" : "footer footer_text"
          }
        >
          <Col>
            <Footer
              show={showFooter}
              orgId={ORG_ID || orgConfig?.orgId}
              langCode={String(getCookie("lang_code") || "en-US")}
            />
          </Col>
        </RowFooter>
        <Row>
          <Col>
            <Cookies />
          </Col>
        </Row>
      </Container>
      <HelpVideo />
      <HelpInstructions />
      <ModalUserDemoMode
        show={showUserDemoModal}
        handleCloseModal={() => {
          setShowUserDemoModal(false);
        }}
        tittle={`SORRY! YOU CAN’T BOOK THROUGH THIS ACCOUNT`}
        subtittle={`YOU ARE CURRENTLY USING A DEMO ACCOUNT, WHERE YOU CAN ONLY BROWSE THROUGH OUR DEALS BUT NOT BOOK`}
      />
    </SWRConfig>
  );
};
