import { Container, Col } from "react-bootstrap";
import styled from "styled-components";
import Image from "next/image";

export const Div = styled(Container)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 14px;
  align-items: center;
  @media only screen and (max-width: 600px) {
    margin-top: 4rem;
    padding-top: 1rem;
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: #e6e6e6;
  }
`;

export const DivCopyright = styled(Container)`
  justify-content: center;
  text-align: center;
  font-size: 14px;
  display: flex;
  @media only screen and (max-width: 992px) {
    margin-top: 1rem;
  }
`;

export const ColLink = styled(Col)`
  @media only screen and (max-width: 992px) {
    margin-bottom: 1rem;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #e6e6e6;
  }
`;

export const Ul = styled.ul`
  -moz-column-count: ${({ theme }: any) => theme.ncol};
  -webkit-column-count: ${({ theme }: any) => theme.ncol};
  column-count: ${({ theme }: any) => theme.ncol};
  text-align: left;
  @media only screen and (max-width: 992px) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    text-align: left;
  }
`;

export const Li = styled.li`
  margin-bottom: 0.5rem;
`;

export const UlSocialNet = styled.ul`
  text-align: center;
  align-items: center;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  display: flex;
  justify-content: center;
  padding: 0;
  @media only screen and (max-width: 992px) {
    margin-top: 1rem;
  }
`;
export const LiSocialNet = styled.li`
  list-style: none;
  text-align: center;
  align-items: center;
  padding: 0 1rem 0 1rem;
`;
export const ImageSocialLinkContainer = styled.div`
  width: 1.5em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
export const ImageSocialLink = styled(Image)``;

export const Span = styled.span`
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  right: 0;
`;

export const ColLinkText = styled(Col)`
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  right: 0;
  padding-top: 0.5rem;
`;
